import { VehicleStateEnum } from "@/lib/enum/vehicleState.enum";
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";
import vehicleService from "@/services/mrfiktiv/services/vehicleService";
import {
  MrfiktivCreateLeasingContractDtoGen,
  MrfiktivCreateVehicleDocumentDtoGen,
  MrfiktivCreateVehicleDtoGen,
  MrfiktivUpdateLeasingContractDtoGen,
  MrfiktivUpdateVehicleDtoGen,
  MrfiktivUpdateVehicleRegistrationDtoGen,
  MrfiktivUpdateVehicleStateDtoGen,
  MrfiktivVehicleControllerCreateBatchPayloadGen,
  MrfiktivVehicleControllerGetAllParamsGen
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, Mutation, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { VehicleAccessLayer } from "./access-layers/vehicle.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { FleetAggregationModule } from "./fleet-aggregation.store";
import { VehiclePageDataProvider } from "./page-data-provider/vehicle.page-data-provider";

@Module({
  dynamic: true,
  namespaced: true,
  name: "vehicle",
  store
})
export class VehicleStore extends PaginatedBaseStore<IVehicle, MrfiktivVehicleControllerGetAllParamsGen> {
  _data = VehicleAccessLayer;
  _pageProvider = VehiclePageDataProvider;
  _pager = new PageDataHandler(this._data, this._pageProvider);
  filterOptions: PaginationFilterListElement[] = Vehicle.filterables;

  hiddenFilter: IPageFilterElement[] = [
    new PageFilterElement({ key: "state", value: VehicleStateEnum.DELETED, operation: "$ne" })
  ];

  get stateMap() {
    return new Map<string, { color: string; label: string }>([
      [
        VehicleStateEnum.UPCOMING,
        { color: "rgb(224,224,224)", label: "views.fleet.FleetVehicleListView.state.upcoming" }
      ],
      [VehicleStateEnum.ACTIVE, { color: "#9EE09E", label: "views.fleet.FleetVehicleListView.state.active" }],
      [VehicleStateEnum.PARTIAL, { color: "#F9E79F", label: "views.fleet.FleetVehicleListView.state.partial" }],
      [VehicleStateEnum.INACTIVE, { color: "#FFADAD", label: "views.fleet.FleetVehicleListView.state.inactive" }],
      [VehicleStateEnum.DELETED, { color: "blue-grey", label: "views.fleet.FleetVehicleListView.state.deleted" }]
    ]);
  }

  private _vehicle: IVehicle | undefined = undefined;

  private _vehicleLookup: Map<string, IVehicle> = new Map();

  get vehicle() {
    return this._vehicle;
  }

  get vehicles() {
    return this._data.entities;
  }

  /**
   * Gets all vehicles in a lookup table
   *
   */
  get vehicleLookup() {
    return;
    return this._vehicleLookup;
  }

  @Mutation
  mutateVehicle(vehicle: IVehicle) {
    this._vehicle = vehicle;
  }

  @Action
  async create(data: { partnerId: string; data: MrfiktivCreateVehicleDtoGen }) {
    const res = await vehicleService.create(data.partnerId, data.data);

    const vehicle = new Vehicle(res);
    this._data.set(vehicle);
    this.context.commit("mutateVehicle", vehicle);
    FleetAggregationModule.parse([vehicle]);

    return vehicle;
  }

  @Action
  async createBatch(data: { partnerId: string; data: MrfiktivVehicleControllerCreateBatchPayloadGen }) {
    const res = await vehicleService.createBatch(data.partnerId, data.data);

    const vehicles = res.map(v => {
      const vehicle = new Vehicle(v);
      this._data.set(vehicle);

      return vehicle;
    });

    return vehicles;
  }

  @Action
  async createContract(data: { partnerId: string; vehicleId: string; data: MrfiktivCreateLeasingContractDtoGen }) {
    const res = await vehicleService.createContract(data.partnerId, data.vehicleId, data.data);

    const vehicle = new Vehicle(res);
    this._data.set(vehicle);
    this.context.commit("mutateVehicle", res);

    (await FleetAggregationModule.getVehicleAggregation(data.vehicleId))
      ?.updateContracts(res.contracts)
      .parseContractDates();

    return vehicle;
  }

  @Action
  async createDocument(data: { partnerId: string; vehicleId: string; data: MrfiktivCreateVehicleDocumentDtoGen }) {
    const res = await vehicleService.createDocument(data.partnerId, data.vehicleId, data.data);

    const vehicle = new Vehicle(res);
    this._data.set(vehicle);
    this.context.commit("mutateVehicle", vehicle);

    return vehicle;
  }

  @Action
  async delete(data: { partnerId: string; vehicleId: string }) {
    const res = await vehicleService.delete(data.partnerId, data.vehicleId);

    const vehicle = new Vehicle(res);
    this._data.delete(vehicle);
    this.context.commit("mutateVehicle", vehicle);
    FleetAggregationModule.removeAggregation(data.vehicleId);

    return vehicle;
  }

  @Action
  async deleteContract(data: { partnerId: string; vehicleId: string; contractId: string }) {
    const res = await vehicleService.deleteContract(data.partnerId, data.vehicleId, data.contractId);

    const vehicle = new Vehicle(res);
    this._data.set(vehicle);
    this.context.commit("mutateVehicle", vehicle);
    (await FleetAggregationModule.getVehicleAggregation(data.vehicleId))
      ?.updateContracts(vehicle.contracts)
      .parseContractDates();
    return vehicle;
  }

  @Action
  async deleteDocument(data: { partnerId: string; vehicleId: string; documentId: string }) {
    const res = await vehicleService.deleteDocument(data.partnerId, data.vehicleId, data.documentId);

    const vehicle = new Vehicle(res);
    this._data.set(vehicle);
    this.context.commit("mutateVehicle", vehicle);

    return vehicle;
  }

  @Action
  async getAll(data: { partnerId: string }) {
    await this.fetchAll({
      partnerId: data.partnerId
    });
  }

  @Action
  async getOne(data: { partnerId: string; vehicleId: string }) {
    const res = await vehicleService.getOne(data.partnerId, data.vehicleId);

    const vehicle = new Vehicle(res);
    this._data.set(vehicle);
    this.context.commit("mutateVehicle", vehicle);

    return vehicle;
  }

  @Action
  async update(data: { partnerId: string; vehicleId: string; data: MrfiktivUpdateVehicleDtoGen }) {
    const res = await vehicleService.update(data.partnerId, data.vehicleId, data.data);

    const vehicle = new Vehicle(res);
    this._data.set(vehicle);
    this.context.commit("mutateVehicle", vehicle);

    (await FleetAggregationModule.getVehicleAggregation(data.vehicleId))
      ?.updateMileages(vehicle.mileages)
      .parseMileageDates();

    return vehicle;
  }

  @Action
  async updateState(data: { partnerId: string; vehicleId: string; data: MrfiktivUpdateVehicleStateDtoGen }) {
    const res = await vehicleService.updateState(data.partnerId, data.vehicleId, data.data);

    const vehicle = new Vehicle(res);
    this._data.set(vehicle);
    this.context.commit("mutateVehicle", vehicle);

    return vehicle;
  }

  @Action
  async updateContract(data: {
    partnerId: string;
    vehicleId: string;
    contractId: string;
    data: MrfiktivUpdateLeasingContractDtoGen;
  }) {
    const res = await vehicleService.updateContract(data.partnerId, data.vehicleId, data.contractId, data.data);

    const vehicle = new Vehicle(res);
    this._data.set(vehicle);
    this.context.commit("mutateVehicle", vehicle);
    (await FleetAggregationModule.getVehicleAggregation(data.vehicleId))
      ?.updateContracts(vehicle.contracts)
      .parseContractDates();

    return vehicle;
  }

  @Action
  async updateDocument(data: {
    partnerId: string;
    vehicleId: string;
    documentId: string;
    data: MrfiktivCreateVehicleDocumentDtoGen;
  }) {
    const res = await vehicleService.updateDocument(data.partnerId, data.vehicleId, data.documentId, data.data);

    const vehicle = new Vehicle(res);
    this._data.set(vehicle);
    this.context.commit("mutateVehicle", vehicle);

    return vehicle;
  }

  @Action
  async updateRegistration(data: {
    partnerId: string;
    vehicleId: string;
    data: MrfiktivUpdateVehicleRegistrationDtoGen;
  }) {
    const res = await vehicleService.updateRegistration(data.partnerId, data.vehicleId, data.data);

    const vehicle = new Vehicle(res);
    this._data.set(vehicle);
    this.context.commit("mutateVehicle", vehicle);
    (await FleetAggregationModule.getVehicleAggregation(data.vehicleId))?.updateRegistration(vehicle.registration);

    return vehicle;
  }
}

export const VehicleModule = getModule(VehicleStore);
